import React from "react";
import { withRouter, Link } from "react-router-dom";

import './termsOfUse.scss';

const CopyrightStatement = ({ type = 'page', subType }) => {

    const openNewWindow = (val) => {
        if (val) {
          window.open(`${window.location.origin}/#/${val}`);
        }    
    }

    return (
        <div className={`tc-pp-cs-main ${type}-type`}>
            <section className="cs-sec">
                {type === 'page' ? <h4 style={{ color: '#002245' }}>Copyright and Disclaimer Notice</h4> : ''}
                {/* <p style={{ fontSize: '12px' }}>Last Updated on {moment('2023-01-21T10:00:00').format('MMM DD, YYYY')}</p> */}
                <p>&#169; International Finance Corporation 2023.  All rights reserved.<br />
                    2121 Pennsylvania Avenue, N.W.<br />
                    Washington, D.C. 20433<br />
                    Internet: <a style={{ textDecoration: "underline" }} target="_blank" href="https://www.ifc.org">www.ifc.org</a>
                </p>
                <p>The material on this Site is copyrighted. Copying and/or transmitting portions or all of the content on this Site without permission may be a violation of applicable law.  IFC does not guarantee the accuracy, reliability or completeness of the content included in this Site, or for the conclusions or judgments described herein, and accepts no responsibility or liability for any omissions or errors (including, without limitation, typographical errors and technical errors) in the content whatsoever or for reliance thereon.</p>
            </section>
            {/* {type === 'page' && subType !== 'P' ?
            <div className="cs-foot footer-row" style={{background: "#fff", padding: 0}}>
                <PublicFooter pType={"PR"}></PublicFooter>
            </div>         
                
                : ''
            } */}
        </div>
    );
}

export default withRouter(CopyrightStatement);