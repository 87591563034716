import React from "react";
import { withRouter, Link } from "react-router-dom";

import './termsOfUse.scss';

const PrivacyPolicy = ({ type = 'page', subType }) => {

    const openNewWindow = (val) => {
        if (val) {
          window.open(`${window.location.origin}/#/${val}`);
        }    
    }

    return (
        <div className={`tc-pp-cs-main ${type}-type`}>
            <section className="pp-sec">
                {type === 'page' ? <h4 style={{ color: '#002245' }}>International Finance Corporation Privacy Notice (MALENA)</h4> : ''}
                <p style={{ fontSize: '12px' }}>Dated: June 2024</p>
                <p style={{ fontSize: '12px' }}>Last updated: June 2024</p>

                <p><strong>Introduction</strong><br />
                    The MALENA ESG Sentiment Analysis model and any related MALENA product offering made available via web-based interfaces, 
                    Application Programming Interfaces (“<strong>APIs</strong>”) or any other method  (collectively, the “<strong>Site</strong>”) is an offering of the International 
                    Finance Corporation (also referred to herein as “<strong>IFC</strong>”, “<strong>us</strong>” or “<strong>we</strong>”), a member organization of the World Bank Group (“<strong>WBG</strong>”).  
                    Information about IFC and our mission can be found at this <a href="http://www.ifc.org" target="_blank">link</a>. 
                </p>
                <p>IFC is strongly committed to protecting privacy. Please review this Privacy Notice (this “<strong>Notice</strong>”) for an explanation of IFC’s privacy practices for the Site. 
                    This Notice describes the Personal Data that we collect from users (“<strong>user</strong>” or “<strong>you</strong>”) who access or use the Site and how we use, share and protect that Personal Data. 
                    “<strong>Personal Data</strong>” is any information relating to an identified or identifiable individual.
                </p>
                <p>This Notice applies exclusively to the Site and does not apply to any other IFC websites, applications or practices.</p>
                <p><strong>Acceptance of and Changes to These Privacy Terms</strong><br />
                    We may update this Notice at any time. Changes to this Notice will be dated and will be effective from the date specified forward so that users are always aware of what personal data we collect, how we use it, and under what circumstances we disclose it. You are advised to check our Privacy Notice regularly for updates.
                </p>
                <p><strong>I.  What Personal Data Does IFC Collect?</strong><br />
                    When you access or otherwise use the Site, we may collect Personal Data about you and your interaction with the Site.  We may collect Personal Data about you through: (A) information you provide; (B) automatic tracking technologies; and (C) third parties.
                </p>
                <p><u>Personal Data You Provide</u><br />
                    When you access the Site, you may choose to provide Personal Data about yourself such as, but not limited to, your: name, address, email address, telephone number, job title, and company name. By way of example, you may choose to provide your information in the following circumstances: 
                    <ul>
                        <li>To register or otherwise access the Site or certain portions or features of the Site;</li>
                        <li>To request support;</li>
                        <li>To enter or answer quick surveys, quizzes or benchmarking surveys;</li>
                        <li>To register for events and conferences;</li>
                        <li>To contact us for further information; or</li>
                        <li>To provide Feedback on the Site.</li>
                    </ul>
                </p>
                <p><u>Automatically Collected Personal Data</u><br />
                    We may use online identification technologies, such as cookies, web beacons, or pixels on some pages of our Site (“<strong>Tracking Technologies</strong>”). The information we collect using these technologies includes: IP address, browser type and language, access time, and referring website. Cookies are small text files placed on the hard drive of your device that assist us in providing a more customized website experience. For example, a cookie can be used to store registration information in a website area so that its user does not need to re-enter it on subsequent visits. 
                    You can set your browser to accept all cookies, to reject all cookies, or to notify you whenever a cookie is offered so that you can decide each time whether to accept it (for more information about cookies, please visit <a href="http://www.allaboutcookies.org" target="_blank">http://www.allaboutcookies.org</a>). We may also use Tracking Technologies from marketing partners, third party sites, and social media platforms. We use these Tracking Technologies to understand how visitors navigate to our Site from others, compile aggregated statistics about visitors who interact with our content, and to provide more pertinent information to our visitors.
                </p>
                <p><u>Third-Party Analytics</u><br />
                    We may also use website analytics providers which may set cookies in your browser. Site activity information collected by these analytics providers may be combined with Personal Data held by IFC.
                </p>
                <p>When we send you emails, we may use third-party technologies to determine whether the email has been opened and whether the links contained within the message have been clicked on. We may combine information collected through these means with other information we maintain about you in order to measure interest in our specific offerings and email campaigns, improve our offerings to specific target audiences, and tailor our interactions with you.</p>
                <p><strong>II.  How Does IFC Use the Personal Data it Collects?</strong><br />
                    We may use the Personal Data that we collect about you for any or all of the following purposes:
                    <ul>
                        <li>To respond to your requests or inquiries, such as to answer your questions sent through the “Contact Us” form on the Site or to confirm your purchases and registrations on the Site;</li>
                        <li>To communicate with you regarding IFC events, information, resources or services that may be available or of interest to you;</li>
                        <li>To customize the Site for you by, for example, not having to enter information like your address more than once or to help you quickly find information that is relevant to you based on your interests;</li>
                        <li>For IFC’s business purposes, such as to help us explore ways to improve our products and services, to help us improve the Site, or to help us create Site content areas most relevant to you, including by requesting feedback, and for related internal purposes such as data analysis, legal analysis, audits, enhancing the Site and identifying usage trends;</li>
                        <li>To help us operate and improve the Site (such as by analyzing Site visitor trends, including usage of certain pages or functions on the Site), such as model accuracy, taxonomy scope, and data visualizations; and</li>
                        <li>To maintain and improve the safety and security of the Site.</li>
                    </ul>
                </p>
                <p><strong>III.  With Whom Does IFC Share the Personal Data It Collects?</strong><br />
                    We may share the Personal Data that we collect about you as follows:
                    <ol type="A">
                        <li><strong>WBG Entities.</strong> To fulfill IFC’s mission as stated in its <a href="https://www.ifc.org/wps/wcm/connect/corp_ext_content/ifc_external_corporate_site/about+ifc_new/ifc+governance/articles/about+ifc+-+ifc+articles+of+agreement" target="_blank">Articles of Agreement</a>, we share Personal Data with other members of the WBG.</li>
                        <li><strong>Service Providers.</strong> We share Personal Data with unaffiliated third parties who provide us with services, such as those entities that assist us with technology, data analysis, website hosting/administration or similar services. We do not share Personal Data with unaffiliated third parties for their own marketing purposes.</li>
                        <li><strong>Events and Publications.</strong>  We may also share Personal Data with co-sponsors of IFC events and publications related to the Site.</li> 
                        <li><strong>Remedies and Enforcement.</strong> We may use or disclose Personal Data as we believe to be appropriate in the following circumstances: (i) to enforce the terms and conditions for use of the Site, including this Notice; (ii) to protect and defend our rights and property; (iii) to protect the interests of IFC or others; and (iv) to permit us to pursue available remedies or limit the damages that we may sustain.</li>
                        <li><strong>External Parties with Whom You Share Site Information.</strong> When you send information to a third party directly from the Site, that party will see your email address.</li>
                    </ol>
                </p>
                <p><strong>IV. How Can You Manage Personal Data We Maintain?</strong><br />
                    If you would like to review, correct, update or remove Personal Data about you that you previously provided to us through the Site, you may submit new information by contacting: <a href={`mailto: malena@ifc.org`}>malena@ifc.org</a>. Please note that we are not responsible for correcting, updating or deleting any information in the databases of third parties.
                </p>
                <p>Should you wish to unsubscribe from our mailing list or any registrations, we will provide instructions within the appropriate area of the Site or in communications to you; or you may contact us at: <a href={`mailto: malena@ifc.org`}>malena@ifc.org</a>.</p>
                <p><strong>V. How Does IFC Protect and Maintain Personal Data Collected Through the Site?</strong><br />
                    We use reasonable organizational, technical and administrative measures to help protect against the loss, misuse and alteration of the Personal Data collected through the Site and under our control. However, no data transmission over the Internet or data storage system can be guaranteed to be fully secure. Please do not send us Personal Data you consider sensitive through the Site.  In addition, we limit access to your Personal Data to those employees, agents, contractors and other third parties who have a business need to know and subject to appropriate contractual restrictions.
                </p>
                <p><strong>VI. How Long Does IFC Retain Personal Data Collected Through the Site?</strong><br />
                    We will hold your information for a reasonable period after receiving such information in accordance with our records retention and disposition schedules or as otherwise stated in the Site’s <span onClick={() => {openNewWindow(`${subType === 'P' ? "public/terms-conditions": "static/terms-conditions"}`)}} className="tr-links">Terms of Use</span>.
                </p>
                <p><strong>VII. Third Party Sites.</strong><br />
                    The Site may contain links to websites operated by parties not affiliated with IFC. These linked sites are not under IFC’s control and we are not responsible for the privacy practices or the contents of any such unaffiliated parties or their websites. If you provide Personal Data through any such third-party website, the Personal Data will be collected by, and controlled by the privacy policy (if any) of, that third party. We recommend that you familiarize yourself with the privacy policies and practices of any such third parties, which are not governed by this Notice. 
                </p>
                <p><strong>VIII. Use of the Site by Children.</strong><br />
                    The Site is not directed to children. If you are a minor, please do not submit any Personal Data to us.
                </p>
                <p><strong>IX. International Transfers of Information.</strong><br />
                    By using the Site, you are deemed to understand and consent to the collection and processing of your Personal Data by the International Finance Corporation, an international organization established by <a href="https://www.ifc.org/wps/wcm/connect/corp_ext_content/ifc_external_corporate_site/about+ifc_new/ifc+governance/articles/about+ifc+-+ifc+articles+of+agreement" target="_blank">Articles of Agreement</a> among its member countries.
                </p>
                <p><strong>X. Contacting Us.</strong><br />
                    If you have any questions or concerns related to this Privacy Notice or the information practices of the Site, please send us an e-mail at <a href={`mailto: malena@ifc.org`}>malena@ifc.org</a> or write to us at the address below.
                </p>
                <p>Sustainability Advice and Solutions<br />
                    International Finance Corporation<br />
                    2121 Pennsylvania Avenue, NW<br />
                    Washington, DC 20433<br />
                    United States of America
                </p>
                <p><strong>XI. More Information and Data Privacy Office.</strong><br />
                    For more information on IFC’s approach to personal data protection, including how to directly contact or request information from IFC’s Data Privacy Office, please visit <a href="http://www.ifc.org/privacy" target="_blank" className="tr-links">ifc.org/privacy</a>.
                </p>
            </section>
            {/* {type === 'page' && subType !== 'P'  ?
            <PublicFooter pType={"PR"}></PublicFooter>               
                 : ''
            } */}
        </div>
    );
}

export default withRouter(PrivacyPolicy);