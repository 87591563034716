import React, { useState } from "react";
import { Link } from 'react-router-dom';

import { postContactUs } from "../SelfService/SelfServiceApi";
import ContactUs from "../../containers/Layout/ContactUs/ContactUs";
import './publicFooter.scss';

const PublicFooter = ({ pType, noPartners = false }) => { // pType = w - waitlist, pr- private

    const [showContactModal, setShowContactModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [reqId, setReqId] = useState('');

    const openContactUs = () => {
        setShowContactModal(true);
    }

    const sendUserMessage = (conatctPayload) => {
        const { postContactUsData } = postContactUs(conatctPayload);
        postContactUsData().then(res => {
            if (res) {
                setReqId(res.id);
                setShowSuccessModal(true);
            }
        })
    }

    const handleContactModalClick = (clickType, ContactUsData) => {
        const payload = ContactUsData
        if (clickType === 'Y') {
            sendUserMessage(payload)
        } else if (clickType === 'X') {
            setShowSuccessModal(false);
            setShowContactModal(false);
        } else {
            setShowContactModal(false);
        }
    }

    return (
        <div className={`pub-footer-main`}>
            {noPartners ? '' :
                <div className="main-part-wrapper">
                    <div className="part-row row">
                        <div className="col-12 part-head">
                            <h2 className="main-h-2">{`In partnership with`}</h2>
                        </div>

                        <div className="col-12 part-content">
                            <div className="each-part">
                                <div className="p-wrap den">
                                    <span><img width="251" height="57.5" src="../../../assets/img/extPre/den-l.svg" alt="ministry of foreign affairs denmark partner"></img></span>
                                </div>
                            </div>
                            <div className="each-part sec">
                                <div className="p-wrap kor">
                                    <span className="k"><img width="107" height="71" src="../../../assets/img/extPre/kor-l.svg" alt="korea"></img></span>
                                    <span className="minstry"><img width="87" height="85.13" src="../../../assets/img/extPre/min-l.svg" alt="ministry of economy and finace korea partner"></img></span>
                                </div>
                            </div>
                            <div className="each-part thi">
                                <div className="p-wrap fias">
                                    <span><img width="200" height="57" src="../../../assets/img/extPre/fias-l.svg" alt="fias partner"></img></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-globe" style={{ background: `url('../../../assets/img/extPre/bg-globe.svg') no-repeat 100%` }}></div>
                </div>
            }
            <div className="f-t-row">
            <div className="foot-row">
                <div className="img-row">
                    <span><a target="_blank" href="https://www.ifc.org/en/home"><img width="155" height="34" src="../../assets/img/extPre/ifc-white.svg" alt="ifc logo"></img></a></span>
                </div>
                <div className="links-row">
                    {pType === 'W' ? '' : pType === 'PR' ?   // W = waiting, PR = private
                        <>
                            <div className="link pr-4">
                                <span>
                                    <Link to="/about-malena" rel="noopener noreferrer">About MALENA</Link>
                                </span>
                            </div>
                            <div className="link pr-4">
                                <span>
                                    {/* <Link to="/public/contact-us" rel="noopener noreferrer">Contact Us</Link> */}
                                    <span><a href="javascript:;" className="pr-2" onClick={() => {openContactUs()}}>Contact Us</a></span>
                                </span>
                            </div>
                            <div className="link pr-4">
                                <span>
                                    <Link to="/static/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Notice</Link>
                                </span>
                            </div>
                            <div className="link pr-4">
                                <span>
                                    <Link to="/static/terms-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</Link>
                                </span>
                            </div>
                        </>
                        :
                        <>
                            <div className="link pr-4">
                                <span>
                                    <Link to="/public/contact-us" rel="noopener noreferrer">Contact Us</Link>
                                </span>
                            </div>
                            <div className="link pr-4">
                                <span>
                                    <Link to="/public/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Notice</Link>
                                </span>
                            </div>
                            <div className="link pr-4">
                                <span>
                                    <Link to="/public/terms-conditions" target="_blank" rel="noopener noreferrer">Terms of Use</Link>
                                </span>
                            </div>
                        </>
                    }
                    <div className="text"><span>&copy;{` 2023 The World Bank Group, All Rights Reserved`}</span></div>
                </div>
            </div>
            <div className="text-tab"><span>&copy;{` 2023 The World Bank Group, All Rights Reserved`}</span></div>
            </div>
            
            {showContactModal ?
        <ContactUs
          showFlag={showContactModal}
          contactClickHandler={handleContactModalClick}
          successFlag={showSuccessModal}
          requestId={reqId}
        />
        : ''
      }
        </div>
    );
}

export default PublicFooter;