import React, {useEffect, useRef, useState} from "react";
import { withRouter, useHistory } from "react-router-dom";
import PublicFooter from "./PublicFooter";
import PublicHeader from "./PublicHeader";

import { Button } from "reactstrap";

import { getRegistrationLimitApi, sendWaitlistEmailApi, downloadRegPdfApi } from "./publicService";
import { validateSecurityTextPattern, validateEmailPattern } from "./utils/publicUtils";
import PublicDataModal from "./utils/PublicDataModal";
import { getErrorMessages } from "../../common/Utils";
import { isMobileOrTablet } from "./utils/publicUtils";

import './publicHomePage.scss';

import Spinner from "../../common/Spinner/Spinner";

const PublicRegistration = ({loginClick}) => {

    const [isRegLimitReached, setIsRegLimitReached] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [userEmailId, setUserEmailId] = useState('');
    const [hasEmailError, setHasEmailError] = useState(false);
    const [emailErrorType, setEmailErrorType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [waitListProgress, setWaitListProgress] = useState(false);
    const [isPageSrcolled, setIsPageSrcolled] = useState(false);
    const [isMoTab, setIsMoTab] = useState(false);
    const history = useHistory();
    const qrRef = useRef();
    const step3Ref = useRef();
    
    useEffect(() => {
        // start of adobe analytics logic
        document.getElementById('adb-analytics') != null && document.getElementById('adb-analytics').remove();
        const script = document.createElement("script");
        script.src = "./js/AnalyticsRegistration.js";
        script.id = "adb-analytics";
        script.async = true;
        document.body.appendChild(script);
        // end of adobe analytics logic

        getLimitReachedApi();
        if (isMobileOrTablet()) {
            setIsMoTab(true);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', toggleScrollBtn, {passive: true});
        return () => { window.removeEventListener('scroll', toggleScrollBtn) }
    }, [])

    useEffect(() => {
        if (userEmailId) {
            if (validateSecurityTextPattern(userEmailId)) {
                setHasEmailError(true);
                setEmailErrorType(`special charatcters {<^>} are invalid`);
            } else {
                setEmailErrorType(null);
                setHasEmailError(false);
            }
        }

    }, [userEmailId]);

    const getLimitReachedApi = () => {
        getRegistrationLimitApi().then(res => {
            if (res && res.toLowerCase() === 'true') {
                setIsRegLimitReached(true);
            }
            setIsPageLoading(false);
        }).catch(err => {
            // console.log(err? err.status: err);
            setIsPageLoading(false);
        });
    }

    const receiveModalInputs = (type) => {
        if (type === 'C') {
            setShowModal(false);
            navigateTo('H');
        }
        if (type === 'DO') {
            setIsMoTab(false);
        }
    }

    const navigateTo = (type) => {
        if (type === 'H') {
            history.push('/');
        } else if (type === 'S') {
            loginClick();
        }
    }

    const handlePdfClick = () => {
        // download public reg guide pdf from api
        const {getPdfReg} = downloadRegPdfApi();
        getPdfReg().then(res => {
            if (res && res.status === 200) {
                // console.log(res.body);

                // open the pdf in next tab
                let test_blob = new Blob([res.data], { type: 'application/pdf' });
                let blobURL = window.URL.createObjectURL(test_blob);
                window.open(blobURL,'_system2');

                // // Download the file 
                // let a = document.getElementById("pdf_reg_down");
                // // a.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf;base64' }));
                // a.href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/octet-stream'}));
                // a.download = 'Malena_Registration_guide' + ".pdf";
                // // a.style.display = 'none';
                // // let dbody = document.body;
                // // dbody.appendChild(a);
                // a.click();
            }
        });
    }
    
    const handleSubmit = () => {
        if (userEmailId) {
            if (validateEmailPattern(userEmailId)) {
                setHasEmailError(true);
                setEmailErrorType(`Please enter a valid email address`);
            } else {
                // send to api
                let pay = {"waiting_email_id": userEmailId.trim()}
                setWaitListProgress(true);
                sendWaitlistEmailApi(pay).then(res => {
                    if (res && res === 'true') {
                        // show a popup
                        setShowModal(true);
                        setUserEmailId('');
                    } else {
                        setHasEmailError(true);
                        setEmailErrorType(getErrorMessages('500'));
                    }
                    setWaitListProgress(false);
                }).catch(err => {
                    // show 500 error message
                    setHasEmailError(true);
                    setEmailErrorType(getErrorMessages('500'));
                    setWaitListProgress(false);
                });
            }
        }
    }

    const scrollToQR = ()=>{
        if(qrRef && qrRef.current){
            qrRef.current.scrollIntoView({behavior: "smooth"});
        }
    }
    const scrollToStep3 = ()=>{
        if(step3Ref && step3Ref.current){
            step3Ref.current.scrollIntoView({behavior: 'smooth'});
        }
    }
    const scrollToTop = ()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    const toggleScrollBtn = ()=>{
        if(document.documentElement.scrollTop > 100){
            setIsPageSrcolled(true);
        }else{
            setIsPageSrcolled(false);
        }
    }

    return (
        <div className="reg-main">
            <PublicHeader applyWhite={true} loginC={loginClick}></PublicHeader>
            {isPageLoading ?
                <div className="spin-div"><Spinner></Spinner></div>
                :
                <div className={`reg-body ${isRegLimitReached ? 'lr': ''}`}>
                    {isRegLimitReached ?
                        <div className="reg-limit-head">
                            <div className="col-12">
                                <h1>Sign up</h1>
                            </div>
                        </div>
                        :
                        <>
                            <div className="row btn-row">
                                <div className="col-12">
                                    <Button color="primary" size="md" onClick={() => { navigateTo('S') }} name="signup" className="signup-btn">{`Proceed to Sign Up`}</Button>
                                    <button onClick={() => {handlePdfClick()}} className="btn ml-3 inst-pdf">{`Open Instructions in a new tab`}</button>
                                </div>
                            </div>
                            <div className="reg-head">
                                <div className="col-12">
                                    <h1>{`Sign Up Instructions`}</h1>
                                </div>
                                <div className="col-12">
                                    <p className="sub-info">Access to MALENA requires Multifactor authentication. <span onClick={scrollToQR}>Click here</span> to download the Microsoft Authenticator app</p>
                                </div>
                            </div>
                        </>

                    }       
                    <div className="reg-content">
                        {isRegLimitReached ?
                            <div className="reg-limit">
                                <div className="row hea">
                                    <div className="col-12">
                                        <h2 className="main-h-2">{`Oh no! MALENA is currently at capacity.`}</h2>
                                    </div>
                                </div>
                                <div className="row txt">
                                        <div className="col-12">
                                            <p>{`A lot of people are exploring MALENA right now. We’re doing our best to ensure everyone has access, so please check back soon. To join the waitlist, please enter your email address below. We will notify you as soon as MALENA is available again.`}</p>
                                        </div>
                                </div>
                                <div className={`row inp ${hasEmailError ? 'has-err': ''}`}>
                                    <div className="col-12">
                                        <div className="inp-wrap">
                                        <input id="email_user" type="email" value={userEmailId} name="emal input" autoComplete="off"
                                placeholder="Enter email address" onChange={(e) => { setUserEmailId(e.target.value) }} maxLength={100}></input>
                                <span><Button color="primary" onClick={() => handleSubmit()} name="submit"><div className="d-flex"><span>{`Submit `}</span>{waitListProgress ? <span className="pl-2"><i className="fa fa-spin fa-spinner"></i></span>: ''}</div></Button></span>
                                        </div>                                        
                                    </div>
                                    {hasEmailError && emailErrorType ? <div className="color-red err-msg"><span>{emailErrorType}</span></div>: ''}
                                </div>
                                <div className="row bac">
                                    <div className="col-12">
                                        <span className="btn-2" onClick={() => { navigateTo('H') }}>{`Back to Main Page`}</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="reg-container">
                                {/* <div className="blur-img col-lg-5 col-md-5 col-sm-12">
                                    <div className="img-enc">
                                        <img width="248" height="185" alt="sample" src="../../../assets/img/extPre/wbg-signup-blur.svg"></img>
                                        <div className="high"><span><span className="b">{`No Account?`}</span><span className="bl">{` Create One!`}</span></span></div>
                                    </div>
                                </div> */}
                                {/* <div className="su col-lg-7 col-md-7 col-sm-12">
                                     <div className="row">
                                        <div className="col-12">
                                            <h2 className="main-h-2">{`Register using Microsoft Azure`}</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p>{`You will be redirected to the Microsoft Azure account registration process to sign up for MALENA. On the next page, click “Create one!” to cerate a new account. Download the MALENA registration guide`}
                                            <span onClick={() => {handlePdfClick()}} className="pdf-link">{`here`}</span>{`.`}</p>
                                            <a style={{ display: 'none' }} id="pdf_reg_down"></a>
                                        </div>
                                    </div> 
                                </div> */}                                
                                <div className="reg-instruction box">
                                    <div className="step1 pb-3">
                                        <div className="d-flex pl-5 head-box">
                                            <img width="40" height="40" src={'../../../assets/img/extPre/instructions/insLogP.svg'} alt="Email"/>
                                            <div className="step-head"><p>1. Click on Proceed to Sign up</p></div>
                                        </div>
                                        <div className="reg-slide">
                                            <div className="steps">
                                                <div className="ss-wrapper">
                                                    <div className="d-flex"><span className="pr-2">1.</span><p>{`Click “Create One”`}</p></div>
                                                    <div className="ss-box">
                                                        <img src="../../../assets/img/extPre/instructions/RegSS1.svg" alt="ScreenShot-1" height="243" width="187" className="ss1"/>
                                                    </div>
                                                </div>
                                                <div className="next-arw align-self-center">
                                                    <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                </div>
                                                <div className="ss-wrapper">
                                                    <div className="d-flex"><span className="pr-2">2.</span><p>{`Sign up using your email, Microsoft or Google account`}</p></div>
                                                    <div className="ss-box">
                                                        <img src="../../../assets/img/extPre/instructions/RegSS2.svg" alt="ScreenShot-2" height="242" width="235" className="ss2"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="step2">
                                        <div className="step2-a">
                                            <div className="d-flex pl-5 head-box">
                                                <img width="40" height="40" src={'../../../assets/img/extPre/instructions/insLogE.svg'} alt="Email"/>
                                                <div className="step-head"><p>2a. Sign up using your Email account</p></div>
                                            </div>
                                            <div className="reg-slide">
                                                <div className="steps">
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">1.</span><p>{`Enter your email address to Sign up`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS3.svg" alt="ScreenShot-3" height="236" width="227" className="ss3"/>
                                                        </div>
                                                    </div>
                                                    <div className="next-arw align-self-center">
                                                        <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                    </div>
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">2.</span><p>{`A verification code will be sent to your email address`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS4.svg" alt="ScreenShot-4" height="205" width="247" className="ss4"/>
                                                        </div>
                                                    </div>
                                                    <div className="next-arw align-self-center arw-off">
                                                        <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                    </div>
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">3.</span><p>{`You will receive an email from WBG with an 8 digit account verification code`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS5.svg" alt="ScreenShot-5" height="136" width="249" className="ss5"/>
                                                        </div>
                                                    </div>
                                                    <div className="next-arw align-self-center tab-arw">
                                                        <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                    </div>                                                
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">4.</span><p>{`Enter the 8-digit account verification code`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS6.svg" alt="ScreenShot-6" height="205" width="247" className="ss6"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row skip-row">
                                            <div className="col-12">
                                                <p>Skip to <span onClick={scrollToStep3}>Step 3</span> below</p>
                                            </div>
                                        </div>
                                        <div className="step2-b">
                                            <div className="d-flex pl-5 head-box">
                                                <img width="40" height="40" src={'../../../assets/img/extPre/instructions/insLogM.svg'} alt="Microsoft"/>
                                                <div className="step-head"><p>2b. Sign up using a Microsoft account</p></div>
                                            </div>
                                            <div className="reg-slide">
                                                <div className="steps">
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">1.</span><p>{`Enter your email address to Sign up`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS7.svg" alt="ScreenShot-7" height="224" width="234" className="ss7"/>
                                                        </div>
                                                    </div>
                                                    <div className="next-arw align-self-center">
                                                        <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                    </div>
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">2.</span><p>{`Enter the password associated with this email address`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS8.svg" alt="ScreenShot-8" height="189" width="249" className="ss8"/>
                                                        </div>
                                                    </div>
                                                </div>   
                                            </div>
                                        </div>
                                        <div className="row skip-row">
                                            <div className="col-12">
                                                <p>Skip to <span onClick={scrollToStep3}>Step 3</span> below</p>
                                            </div>
                                        </div>
                                        <div className="step2-c">
                                            <div className="d-flex pl-5 head-box">
                                                <img width="40" height="40" src={'../../../assets/img/extPre/instructions/insLogG.svg'} alt="Google"/>
                                                <div className="step-head"><p>2c. Sign up using a Google account</p></div>
                                            </div>
                                            <div className="reg-slide">
                                                <div className="steps">
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">1.</span><p>{`Enter your email address to Sign up`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS9.svg" alt="ScreenShot-9" height="248" width="209" className="ss9"/>
                                                        </div>
                                                    </div>
                                                    <div className="next-arw align-self-center">
                                                        <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                    </div>
                                                    <div className="ss-wrapper">
                                                        <div className="d-flex"><span className="pr-2">2.</span><p>{`Enter the password associated with this email address`}</p></div>
                                                        <div className="ss-box">
                                                            <img src="../../../assets/img/extPre/instructions/RegSS10.svg" alt="ScreenShot-10" height="243" width="204" className="ss10"/>
                                                        </div>
                                                        <div ref={step3Ref}>
                                                            {/* The sticky header block some of the view when scroll to step 3 thats why add the ref here for step 3 */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                        <div className="row skip-row">
                                            <div className="col-12">
                                                <p>Skip to <span onClick={scrollToStep3}>Step 3</span> below</p>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div className="step3 py-2">
                                        <div className="d-flex pl-5 head-box">
                                            <img width="40" height="40" src={'../../../assets/img/extPre/instructions/step3.svg'} alt="double back"/>
                                            <div className="step-head"><p>3. Next Steps</p></div>
                                        </div>
                                        <div className="reg-slide">
                                            <div className="steps">
                                                <div className="ss-wrapper">
                                                    <div className="d-flex"><span className="pr-2">1.</span><p>{`Click Accept`}</p></div>
                                                    <div className="ss-box">
                                                        <img src="../../../assets/img/extPre/instructions/RegSS11.svg" alt="ScreenShot-11" height="242" width="204" className="ss11"/>
                                                    </div>
                                                </div>
                                                <div className="next-arw align-self-center">
                                                    <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                </div>
                                                <div className="ss-wrapper">
                                                    <div className="d-flex"><span className="pr-2">2.</span><p>{`Add more details as requested`}</p></div>
                                                    <div className="ss-box">
                                                        <img src="../../../assets/img/extPre/instructions/RegSS12.svg" alt="ScreenShot-12" height="229" width="244" className="ss12"/>
                                                    </div>
                                                </div>
                                                <div className="next-arw align-self-center arw-off">
                                                    <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                                </div>
                                                <div className="ss-wrapper">
                                                    <div className="d-flex"><span className="pr-2">3.</span><p>{`Click Next to set up Multi-Factor Authentication`}</p></div>
                                                    <div className="ss-box">
                                                        <img src="../../../assets/img/extPre/instructions/RegSS13.svg" alt="ScreenShot-13" height="242" width="192" className="ss13"/>
                                                        <div ref={qrRef}>
                                                            {/* The sticky header block some of the view when scroll to the qr section thats why add the ref here for the qr section */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="qr-instruction box">
                                    <div className="d-flex pl-5 pt-4 head-box">
                                        <img width="40" height="40" src={'../../../assets/img/extPre/instructions/step4.svg'} alt="install"/>
                                        <div className="step-head"><p>4. Download the Microsoft Authenticator app to access MALENA</p></div>
                                    </div>
                                    <div className="qr-wrap px-3">
                                        <div className="d-flex align-items-center pl-5">
                                            <img src="../../../assets/img/extPre/instructions/QR1.svg" alt="QR code" height="103" width="103" className="qr-img"/>
                                            <p className="px-4">{`To install the Microsoft Authenticator app on an Android device, scan the QR code, or download it from the Google Play Store.`}</p>
                                        </div>
                                        <div className="d-flex align-items-center pl-5 ios-col">
                                            <img src="../../../assets/img/extPre/instructions/QR2.svg" alt="QR code" height="92" width="92" className="qr-img"/>
                                            <p className="px-4">{`To install the Microsoft Authenticator app for iOS, scan the QR code, or download it from the App Store.`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-instruction box">
                                    <div className="d-flex pl-5 head-box">
                                        <img width="40" height="40" src={'../../../assets/img/extPre/instructions/step5.svg'} alt="install"/>
                                        <div className="step-head"><p>5. Final Steps</p></div>
                                    </div>
                                    <div className="reg-slide">
                                        <div className="steps">
                                            <div className="ss-wrapper">
                                                <div className="d-flex"><span className="pr-2">1.</span><p>{`Download the Microsoft Authenticator app on your mobile device and when prompted, allow notifications. Once you have installed the app, click “Next”`}</p></div>
                                                <div className="ss-box">
                                                    <img src="../../../assets/img/extPre/instructions/RegSS14.svg" alt="ScreenShot-14" height="131" width="248" className="ss14"/>
                                                </div>
                                            </div>
                                            <div className="next-arw align-self-center">
                                                <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                            </div>
                                            <div className="ss-wrapper">
                                                <div className="d-flex"><span className="pr-2">2.</span><p>{`Click “Next” for a QR code.`}</p></div>
                                                <div className="ss-box">
                                                    <img src="../../../assets/img/extPre/instructions/RegSS15.svg" alt="ScreenShot-15" height="130" width="248" className="ss15"/>
                                                </div>
                                            </div>
                                            <div className="next-arw align-self-center arw-off">
                                                <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                            </div>
                                            <div className="ss-wrapper">
                                                <div className="d-flex"><span className="pr-2">3.</span><p>{`Scan the QR code on the Authenticator app and click ‘Next’`}</p></div>
                                                <div className="ss-box">
                                                    <img src="../../../assets/img/extPre/instructions/RegSS16.svg" alt="ScreenShot-16" height="156" width="197" className="ss16"/>
                                                </div>
                                            </div>
                                            <div className="next-arw align-self-center tab-arw">
                                                <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                            </div>  
                                            <div className="ss-wrapper">
                                                <div className="d-flex"><span className="pr-2">4.</span><p>{`Enter the number on your computer screen in the Authenticator App to proceed`}</p></div>
                                                <div className="ss-box">
                                                    <img src="../../../assets/img/extPre/instructions/RegSS17.svg" alt="ScreenShot-17" height="138" width="248" className="ss17"/>
                                                </div>
                                            </div>
                                            <div className="next-arw align-self-center arw-off">
                                                <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                            </div>
                                            <div className="ss-wrapper">
                                                <div className="d-flex"><span className="pr-2">5.</span><p>{`Click ‘Next’ to proceed`}</p></div>
                                                <div className="ss-box">
                                                    <img src="../../../assets/img/extPre/instructions/RegSS18.svg" alt="ScreenShot-18" height="132" width="248" className="ss18"/>
                                                </div>
                                            </div>
                                            <div className="next-arw align-self-center">
                                                <img src="../../../assets/img/extPre/instructions/fwd-arrow.svg" alt="forward-arrow" height="30" width="30"/>
                                            </div>
                                            <div className="ss-wrapper">
                                                <div className="d-flex"><span className="pr-2">6.</span><p>{`Click “Done” to access MALENA`}</p></div>
                                                <div className="ss-box">
                                                    <img src="../../../assets/img/extPre/instructions/RegSS19.svg" alt="ScreenShot-19" height="125" width="251" className="ss19"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                             
                            </div>
                        }
                    </div>
                    {isPageSrcolled && !isRegLimitReached &&
                        <button className="d-flex btn scroll-top-btn" onClick={scrollToTop}>
                            <img width="30" height="30" src={'../../../assets/img/extPre/instructions/step3.svg'} alt="install"/>
                            <div className="btn-text"><p>Scroll to Top</p></div>
                        </button>
                    }          
                
                </div>
            }           
            <div style={{ position: "relative", width: "100%" }}><PublicFooter></PublicFooter></div>
            {showModal ? <PublicDataModal type={'R'} showFlag={showModal} sendClose={receiveModalInputs}></PublicDataModal>: ''}
            {isMoTab ? <PublicDataModal type={'DO'} showFlag={isMoTab} sendClose={receiveModalInputs}></PublicDataModal>: ''}

        </div>
    );
}

export default withRouter(PublicRegistration);


